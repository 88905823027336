import { useTranslation } from 'react-i18next';

export const About = () => {

  const { t } = useTranslation();

  return (
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <img src="img/abouttenta-750x750.webp"
                 className="img-responsive founderPic" alt="tenta founder" />
            <p className="founderPic-description">{t('common:Services.name')}</p>
          </div>
          <div style={{marginTop: 0}} className='col-xs-12 col-md-8 section-title'>
            <h2>{t('common:About.title')}</h2>
            <p>{t('common:About.paragraph1')}</p>
            <p>{t('common:About.paragraph1a')}</p>
            <p><b>{t('common:About.paragraph1b')}</b>
            </p>
          </div>
      </div>
    </div>
</section>
)
  ;
};
