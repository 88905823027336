import { useTranslation } from 'react-i18next';

export const Cookies = ({ setState }) => {

  const { t } = useTranslation();

  const handleCookies = (choice) => {
    localStorage.setItem('useCookies', choice);
    setState((prevState) => {
      return { ...prevState, actionTaken: true, useCookies: choice };
    });
  };

  return (
    <div className="cookie-consent-banner">
      <div className="cookie-consent-banner__inner">
        <div className="cookie-consent-banner__copy">
          <div className="cookie-consent-banner__header">
            {t('common:Cookies.title')}
          </div>
          <div className="cookie-consent-banner__description">
            {t('common:Cookies.description')}
          </div>
        </div>

        <div className="cookie-consent-banner__actions">
          <button className="cookie-consent-banner__cta" onClick={() => handleCookies(true)}>{t('common:Cookies.ok')}</button>
          <button className="cookie-consent-banner__cta cookie-consent-banner__cta--secondary" onClick={() => handleCookies(false)}>{t('common:Cookies.decline')}</button>
        </div>
      </div>
    </div>
  );
};
