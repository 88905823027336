import { useTranslation } from 'react-i18next';

export const Header = (props) => {

  const { t } = useTranslation();

  return (
    <header id='header'>
      <div className='intro'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 col-md-7 intro-text'>
                <h1>
                  {t('common:header.title')}
                  <span></span>
                </h1>
                <p>{t('common:header.paragraph1')}</p>
                <div className='buttonRow'>
                  <a className="primary_button button-intro-anfrage" rel="noreferrer" href={"https://btkk6fsqt0v.typeform.com/to/JAjYWB96"}>{t('common:header.button1')}</a>
                  <a className="secondary_button button-intro-individuell" rel="noreferrer" href={"https://btkk6fsqt0v.typeform.com/to/JAjYWB96"}>{t('common:header.button2')}
                    <span className="material-symbols-outlined" style={{marginTop: 2, verticalAlign: 'middle'}}>chevron_right</span>
                  </a>
              </div>
            </div>
              <div className='col-xs-12 col-md-5 intro-img-container'>
                <img className="intro-img" src="img/header.jpg"  alt="wine box" />
              </div>
          </div>
        </div>
      </div>
    </header>
  )
}
