import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const Navigation = (props) => {
  const { t } = useTranslation();
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <div className='navbar-brand page-scroll'
             style={{cursor: "pointer"}}
          onClick={e => {
            let header = document.getElementById("header");
            e.preventDefault();
            header && header.scrollIntoView();
          }}>
            <div className='row'>
              <button
                onClick={() => window.location.href=window.location.protocol + '//' + window.location.host}
                style={{
                  background: 'none',
                  color: 'inherit',
                  border: 'none',
                  marginLeft: '25px',
                  padding: 0,
                  font: 'inherit',
                  cursor: 'pointer',
                  outline: 'inherit'
                }}>
                <img width="25" height="25" className='logo' alt="tenta wine" src="img/tenta-black_38x38.webp" />
                <span style={{color: 'black', verticalAlign: 'middle'}}>Tenta Box</span>
              </button>
            </div>
            </div>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href={window.location.protocol + '//' + window.location.host + "/"} style={{opacity: 1}}>
                {t('common:nav.home')}
              </a>
            </li>
            <li>
              <a href={window.location.protocol + '//' + window.location.host + "/#features"} style={{opacity: 1}}>
                {t('common:nav.service')}
              </a>
            </li>
            <li>
              <a href={window.location.protocol + '//' + window.location.host + "/#about"} style={{opacity: 1}}>
                {t('common:nav.about')}
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/adrianparpat/recent-activity/all/" target="_blank" rel="noreferrer" style={{opacity: 1}}>
                {t('common:nav.blog')}
              </a>
            </li>
            <li>
              <a href="/contact" style={{opacity: 1}}>
                {t('common:nav.contact')}
              </a>
            </li>
            <li>
              <a target='_blank' rel="noreferrer" href={'https://www.instagram.com/tenta_wine/'}>
                <i style={{margin: 3}} className='fa fa-instagram'></i>
              </a>
            </li>
            <li style={{opacity: 0.5}}>
              <div onClick={() => i18next.changeLanguage("de")}>
                De
              </div>
            </li>
            <li style={{opacity: 0.5}}>
              <div onClick={() => i18next.changeLanguage("en")}>
                Eng
              </div>
            </li>
          </ul>
        </div>
          <a
            aria-label="Whatsapp Link"
            href="https://api.whatsapp.com/send?phone=1734678434"
            rel="noreferrer"
            className="float" target="_blank">
            <i className="fa fa-whatsapp my-float"/>
          </a>
      </div>
    </nav>
  )
}
