import { useTranslation } from 'react-i18next';

export const Numbers = () => {

  const { t } = useTranslation();

  return (
    <section id="numbers">
      <div className="container">
        <div className="overlay-container">
          <div className="overlay-components">
            <div className="overlay-component">
              <p className="overlay-number">
                +1.600
              </p>
              <p className="overlay-text">
                {t('common:numbers.box1')}
              </p>
            </div>
            <div className="overlay-component">
              <p className="overlay-number">
                +6.300
              </p>
              <p className="overlay-text">
                {t('common:numbers.box2')}
              </p>
            </div>
            <div className="overlay-component">
              <p className="overlay-number">
                +120
              </p>
              <p className="overlay-text">
                {t('common:numbers.box3')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
    ;
};
