import { Navigation } from "./navigation";
import { Footer } from "./footer";
import { Form } from "./Form";

export const Contact = (props) => {

  return (
    <div>
      <Navigation />
      <Form />
      <Footer />
    </div>
  )
}
