import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Contact } from './components/contact';
import * as serviceWorker from './serviceWorker';
import i18next from "i18next";
import common_en from "./data/en/translations.json";
import common_de from "./data/de/translations.json";
import { I18nextProvider } from "react-i18next";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/:contact",
    element: <Contact />,
  },
  {
    path: "/*",
    element: <App />,
  },
]);

i18next.init({
  whitelist: ["en", "de", "de-DE", "de-de"],
  fallbackLng: ["de"],
  load: ["de", "en", "de-DE", "de-de"],
  lng: localStorage.getItem("i18next") || navigator.language,
  interpolation: { escapeValue: false }, // React already does escaping   //
  // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
    detection: {
      order: ["querystring", "cookie", "navigator", "localStorage"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
      cookieMinutes: 5,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
