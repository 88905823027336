import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Worktogether } from "./components/worktogether";
import { Benefits } from "./components/benefits";
import { Numbers } from "./components/numbers";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Testimonials } from "./components/testimonials";
import { Services } from "./components/services";
import { Cookies } from './components/Cookies';
import ReactGA from "react-ga4";
import './serviceWorker';
import ReactPixel from 'react-facebook-pixel';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 100,
  speedAsDuration: true,
});

const App = () => {
  const [state, setState] = useState(() => {
    const useCookies = localStorage.getItem('useCookies');
    return {
      actionTaken: !!useCookies,
      useCookies: JSON.parse(useCookies),
    };
  });

  useEffect(() => {
    if (state.useCookies) {
      ReactGA.initialize("G-GWXTR1SFRJ");
      ReactPixel.init('842718867312968');
      ReactPixel.pageView();
      ReactPixel.track('Lead');
    }
  }, [state.useCookies]);

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {!state.actionTaken && <Cookies setState={setState} />}
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <Benefits />
      <Worktogether />
      <Services data={landingPageData.Team} />
      <Numbers />
      <About data={landingPageData.About} />
      <Testimonials data={landingPageData.Testimonials} />
      <Footer />
    </div>
  );
};

export default App;
