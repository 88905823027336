import { useTranslation } from 'react-i18next';

export const Services = () => {

  const { t } = useTranslation();

  return (
    <div id="about2">
      <div className="container">
        <div className="row">
          <div className='col-xs-12 col-md-8 section-title'
               style={{ textAlign: "left", marginTop: 0 }}>
            <h2>{t('common:Steuer.title2')}</h2>
            <p>{t('common:Steuer.text1')}</p>
            <p>{t('common:Steuer.text2')}</p>
            <div style={{ marginTop: '30px', marginBottom: '35px' }}>
              <a className="link_steuern"
                 rel="noreferrer"
                 href={"https://www.steuern.de/geschenke-arbeitnehmer"}
                 target={"_blank"}>
                *{t('common:Tax.link')}
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <img className="standardImage"
                 alt="wine yard"
                 src={t('common:Gallery.largeImage1')} />
          </div>
        </div>
      </div>
    </div>
  );
};
