import { useTranslation } from 'react-i18next';

export const Worktogether = () => {

  const { t } = useTranslation();

  return (
    <section id='worktogether'>
      <div className='worktogether'>
        <div className='col-xs-12 col-md-6'>
          <h1>{t('common:worktogether.title')}</h1>
          <a className="primary_button button-body-anfrage" href="https://btkk6fsqt0v.typeform.com/to/JAjYWB96">{t('common:header.button1')}</a>
        </div>
        <div className='col-xs-3 col-md-1'>
          <div className="worktogetherflow-icons">
            <div className="worktogetherflow-step">
              <span className="material-symbols-outlined" style={{fontSize: 50}}>forward_to_inbox</span>
            </div>
            <div className="vertical-line"/>
            <div className="worktogetherflow-step">
              <span className="material-symbols-outlined" style={{fontSize: 50}}>forum</span>
            </div>
            <div className="vertical-line"/>
            <div className="worktogetherflow-step">
              <span className="material-symbols-outlined" style={{fontSize: 50}}>redeem</span>
            </div>
          </div>
        </div>
        <div className='col-xs-9 col-md-5'>
          <div className="worktogetherflow-texts">
            <div className="worktogetherflow-stepText">
              <h3>{t('common:worktogether.title1')}</h3>
              <p>{t('common:worktogether.text1')}</p>
            </div>
            <div className="worktogetherflow-stepText">
              <h3>{t('common:worktogether.title2')}</h3>
              <p>{t('common:worktogether.text2')}</p>
            </div>
            <div className="worktogetherflow-stepText">
              <h3>{t('common:worktogether.title3')}</h3>
              <p>{t('common:worktogether.text3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
