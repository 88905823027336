import { useTranslation } from 'react-i18next';

export const Benefits = () => {

  const { t } = useTranslation();

  return (
    <section id="benefits">
      <div className="container" style={{width: '100%', margin: 0, padding: 0}}>
        <div className="overlay-container" style={{width: '100%', maxWidth: '90%', borderRadius: 10, padding: 10}}>
         <h2 style={{textTransform: 'none', margin: 10, paddingTop: 0, textAlign: 'center', color: 'white'}}>{t('common:benefits.title')}</h2>
          <div className="overlay-components" style={{margin: 10}}>
            <div className="overlay-component">
                <i className={t('common:Features.icon1')} style={{color: 'white', fontSize: 50, marginTop: 15}}/>
                <h3 style={{color: "white", marginTop: 15, fontWeight: 800}}>{t('common:Features.title1')}</h3>
                <div style={{textAlign: "center", color: 'white', paddingLef: 5, paddingRight: 5}}>
                  <p>{t('common:Features.text1')}</p>
                </div>
            </div>
            <div className="overlay-component">
              <i className={t('common:Features.icon2')} style={{color: 'white', fontSize: 50, marginTop: 15}}/>
              <h3 style={{color: "white", marginTop: 15, fontWeight: 800}}>{t('common:Features.title2')}</h3>
              <div style={{textAlign: "center", color: 'white'}}>
                <p>{t('common:Features.text2')}</p>
              </div>
            </div>
            <div className="overlay-component">
              <i className={t('common:Features.icon3')} style={{color: 'white', fontSize: 50, marginTop: 15}}/>
              <h3 style={{color: 'white', marginTop: 15, fontWeight: 800}}>{t('common:Features.title3')}</h3>
              <div style={{textAlign: "center", color: 'white'}}>
                <p>{t('common:Features.text3')}</p>
              </div>
            </div>
            <div className="overlay-component">
              <i className={t('common:Features.icon4')} style={{color: 'white', fontSize: 50, marginTop: 15}}/>
              <h3 style={{color: 'white', marginTop: 15, fontWeight: 800}}>{t('common:Features.title4')}</h3>
              <div style={{textAlign: "center", color: 'white'}}>
                <p>{t('common:Features.text4')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
    ;
};
