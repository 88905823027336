import { useTranslation } from 'react-i18next';

export const Testimonials = (props) => {

  const { t } = useTranslation();

  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>{t('common:Testimonials.title')}</h2>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src1')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text1')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name1')} </div>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src2')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text2')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name2')} </div>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src5')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text5')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name5')} </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src3')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text3')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name3')} </div>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src4')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text4')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name4')} </div>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={t('common:Testimonials.src6')} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>"{t('common:Testimonials.text6')}"</p>
                <div
                  className='testimonial-meta'> - {t('common:Testimonials.name6')} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
