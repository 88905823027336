import { useTranslation } from 'react-i18next';

export const Features = () => {

  const { t } = useTranslation();

  return (
    <section id='features' className='text-center'>
      <div className='container'>
        <div className='col-xs-12 col-md-12 section-title'>
          <h2>{t('common:Features.title')}</h2>
          <p>{t('common:Features.subtitle')}</p>
        </div>
        <div className='col-md-12 section-title containerCard'>
          <div className='col-xs-12 container__card'>
            <div className='container__cardSvgs'>
              <img alt="Box 2" src={'img/box2-300x300.webp'} className='container__cardPics' title="buzz hover text"/>
              <p className="img__description">
                <h3>{t('common:Pricing.includes')}</h3>
                <p style={{marginLeft: 20, marginRight: 20}}>{t('common:Pricing.description1')}</p>
              </p>
            </div>
            <div className='container__cardContent'>
              <h3 className='container__cardName'>VELVET Red</h3>
              <p className='container__cardPrice'>{t('common:Pricing.employeePrice')}</p>
              <a className="primary_button button-box-1" href="https://btkk6fsqt0v.typeform.com/to/JAjYWB96" style={{padding: '1.5rem 5rem'}}>
                {t('common:Pricing.buy')}
              </a>
            </div>
          </div>
          <div className='col-xs-12 container__card'>
            <div className='container__cardSvgs'>
              <img alt="Box 1" src={'img/box1-400x400.webp'} className='container__cardPics' />
              <p className="img__description">
                <h3>{t('common:Pricing.includes')}</h3>
                <p style={{marginLeft: 20, marginRight: 20}}>{t('common:Pricing.description2')}</p>
              </p>
            </div>
            <div className='container__cardContent'>
              <h3 className='container__cardName'>FANCY Bubbles</h3>
              <p className='container__cardPrice'>{t('common:Pricing.classicPrice')}</p>
              <a href="https://btkk6fsqt0v.typeform.com/to/JAjYWB96" className="primary_button button-box-2" style={{padding: '1.5rem 5rem'}}>
                {t('common:Pricing.buy')}
              </a>
            </div>
        </div>

          <div className='col-xs-12 container__card'>
            <div className='container__cardSvgs'>
              <img alt="Box 3" src={'img/white.png'} className='container__cardPics' />
              <p className="img__description">
                <h3>{t('common:Pricing.includes')}</h3>
                <p style={{marginLeft: 20, marginRight: 20}}>{t('common:Pricing.description3')}</p>
              </p>
            </div>
            <div className='container__cardContent'>
              <h3 className='container__cardName'>FRESH White</h3>
              <p className='container__cardPrice'>{t('common:Pricing.classicPrice')}</p>
              <a href="https://btkk6fsqt0v.typeform.com/to/JAjYWB96" className="primary_button button-box-3" style={{padding: '1.5rem 5rem'}}>
                {t('common:Pricing.buy')}
              </a>
            </div>
          </div>
        </div>
        <div className='col-xs-12 col-md-12 section-title containerCard'>
          <div className='container__card'>
            <div className='container__cardSvgs'>
              <img alt="Box 3" src={'img/box3-300x300.webp'} className='container__cardPics' />
              <p className="img__description">
                <h3>{t('common:Pricing.custom')}</h3>
                <p style={{marginLeft: 20, marginRight: 20}}>{t('common:Pricing.description4')}</p>
              </p>
            </div>
            <div className='container__cardContent'>
              <h3 className='container__cardName'>CUSTOM Box</h3>
              <p className='container__cardPrice'>{t('common:Pricing.customPrice')}</p>
              <a href="https://btkk6fsqt0v.typeform.com/to/JAjYWB96" className="primary_button button-box-4" style={{padding: '1.5rem 5rem'}}>
                {t('common:Pricing.buy')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
