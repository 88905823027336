import { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Form = (props) => {

  const { t } = useTranslation();
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_i3l2u78', 'template_mdv9cui', e.target, 'user_borL7PMtiLNvJKYcaTzdN'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <>
      <section id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>{t('common:Contact.title')}</h2>
                <p>
                  {t('common:Contact.text')}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder={t("common:Contact.messageInput")}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='primary_button'>
                  {t('common:Contact.button')}
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>{t('common:Contact.contactText')}</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'/> {t('common:Contact.addressText')}
                </span>
                {t('common:Contact.address')}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'/> {t('common:Contact.addressText')}
                </span>
                {t('common:Contact.addressTwo')}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> {t('common:Contact.phoneText')}
                </span>{' '}
                {t('common:Contact.phone')}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {t('common:Contact.email')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
